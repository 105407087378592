/* You can add global styles to this file, and also import other style files */
@import 'bootstrap/scss/bootstrap';

@import "./assets/css/LineIcons.2.0.css";

@import "./assets/scss/main.scss";

@import "../node_modules/alertifyjs/build/css/alertify.css";
@import "../node_modules/alertifyjs/build/css/themes/bootstrap.min.css";
@import "../node_modules/alertifyjs//build/css/themes/default.min.css";


.mandatory:after {
    content: " *";
    color: red;
}

.ng-valid[required],
.ng-valid.required {
    border-left: 5px solid #42A948 !important;
    /* green */
}

.ng-invalid.ng-touched:not(form) {
    border-left: 5px solid #a94442 !important;
    /* red */
}
html, body { height: 100%; }
body { margin: 0; font-family: 'Helvetica', "Helvetica Neue", sans-serif; }
