/*======================================
    Hero Area CSS
========================================*/
.dashboard-header {
    position: relative;
    padding: 100px 0 20px 0;
    background: $theme-color;

    @media #{$md} {
        padding: 150px 0 60px 0;
    }

    @media #{$xs} {
        padding: 120px 0 50px 0;
    }
}