/*=============================
	Pricing Table CSS
===============================*/
.pricing-table {
    background-color: $gray;
    .section-title{
        margin-bottom: 50px;
        @media #{$md, $xs}{
            margin-bottom: 30px;
        }
    }
    .single-table {
        border: 1px solid #eee;
        border-radius: 10px;
        margin-top: 30px;
        background-color: $white;
        transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) 0s;

        &:hover {
            &:hover {
                box-shadow: 0px 3px 5px #00000017;
                transform: translateY(-5px);
            }
        }

        .table-head {
            padding: 25px;
            border-bottom: 1px solid #eee;

            .title {
                font-size: 16px;
                font-weight: 600;
                margin-bottom: 20px;
            }

            p {
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
            }

            .price {
                padding: 30px 0;

                .amount {
                    font-size: 30px;
                    font-weight: 700;
                    display: inline-block;

                    .duration {
                        display: inline-block;
                        font-size: 14px;
                        color: #888;
                        font-weight: 400;
                        margin-left: 3px;
                    }
                }
            }

            .button {
                .btn {
                    width: 100%;
                    padding: 12px 30px;
                    font-size: 13px;
                    background-color: $black;

                    &:hover {
                        background-color: $theme-color;
                        color: $white;
                    }
                }
            }
        }

        .table-content {
            padding: 25px;

            .middle-title {
                font-size: 13px;
                font-weight: 500;
                text-transform: uppercase;
                margin-bottom: 25px;
            }

            .table-list {
                li {
                    position: relative;
                    padding-left: 25px;
                    font-size: 14px;
                    margin-bottom: 13px;

                    &:last-child {
                        margin: 0;
                    }

                    i {
                        color: $theme-color;
                        font-size: 14px;
                        position: absolute;
                        left: 0;
                        top: 4px;
                    }
                }
            }
        }
    }
}