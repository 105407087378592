/*======================================
    App Info CSS
========================================*/
.app-info {
    .info-one {
        position: relative;

        .info-image {
            padding: 50px;

            @media #{$md} {
                padding-top: 60px;
            }

            @media #{$xs} {
                padding-top: 50px;
            }

            img {
                width: 100%;
            }
        }

        &.style2 {
            padding-top: 70px;

            .info-text {
                @media #{$md} {
                    padding-top: 60px !important;
                }

                @media #{$xs} {
                    padding-top: 50px !important;
                }
            }

            .info-image {
                @media #{$md} {
                    padding-top: 0;
                }

                @media #{$xs} {
                    padding-top: 0;
                }
            }

            @media #{$md} {
                padding-top: 60px;
            }

            @media #{$xs} {
                padding-top: 50px;
            }

            .info-text {
                padding-left: 40px;

                @media #{$md} {
                    padding: 0;
                }

                @media #{$xs} {
                    padding: 0;
                }
            }
        }

        .info-text {
            .main-icon {
                height: 60px;
                width: 60px;
                line-height: 60px;
                text-align: center;
                display: block;
                border-radius: 8px;
                color: $white;
                font-size: 20px;
                background-color: $theme-color;
                margin-bottom: 30px;
            }

            h2 {
                font-size: 30px;
                font-weight: 700;
                margin-bottom: 20px;
                color: $black;
                line-height: 42px;

                @media #{$md} {
                    font-size: 25px;
                    line-height: 38px;
                }

                @media #{$xs} {
                    font-size: 20px;
                    line-height: 32px;
                }
            }

            p {
                margin-bottom: 15px;
            }

            .button {
                margin-top: 35px;
            }
        }
    }
}