/*======================================
    Features Area CSS
========================================*/
.features {
    background-color: $gray;

    .section-title {
        margin-bottom: 30px;
    }

    .single-feature {
        text-align: left;
        padding: 35px;
        background-color: $white;
        border-radius: 6px;
        position: relative;
        margin-top: 30px;
        border: 1px solid #eee;
        transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) 0s;

        &:hover {
            box-shadow: 0px 3px 5px #00000017;
            transform: translateY(-5px);
        }

        i {
            height: 60px;
            width: 60px;
            line-height: 60px;
            text-align: center;
            display: inline-block;
            background-color: $theme-color;
            color: $white;
            font-size: 20px;
            border-radius: 7px;
            box-shadow: 0px 4px 6px #0000002a;
            margin-bottom: 30px;
        }

        h3 {
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 20px;
        }

        p {
            line-height: 22px;
        }
    }
}