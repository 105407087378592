/*======================================
    Our Achievement CSS
========================================*/

.our-achievement {
    background-color: $theme-color;
    text-align: center;
    padding: 130px 0;

    @media #{$md} {
        padding: 80px 0;
    }

    @media #{$xs} {
        padding: 60px 0;
    }

    .title {
        h2 {
            color: $white;
            font-weight: 700;
            font-size: 35px;
            margin-bottom: 10px;
        }

        p {
            color: $white;
        }
    }

    .single-achievement {
        margin-top: 50px;
        text-align: center;
        padding: 0px 10px;

        h3 {
            font-size: 35px;
            font-weight: 800;
            display: block;
            margin-bottom: 5px;
            color: $white;
        }

        p {
            font-size: 15px;
            color: $white;
            font-weight: 500;
            text-transform: capitalize;
        }
    }

    @media #{$md, $xs} {
        .title {
            h2 {
                font-size: 24px;
                line-height: 32px;
            }
        }

        .single-achievement {
            margin-top: 30px;

            h3 {
                font-size: 28px;
            }
        }
    }
}