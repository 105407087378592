/*======================================
    Hero Area CSS
========================================*/
.hero-area {
    position: relative;
    padding: 160px 0 100px 0;
    background: $theme-color;

    @media #{$md} {
        padding: 150px 0 60px 0;
    }

    @media #{$xs} {
        padding: 120px 0 50px 0;
    }

    .hero-image {
        img {
            width: 100%;
            border-radius: 10px;
            -webkit-filter: drop-shadow(5px 5px 5px #222);
  filter: drop-shadow(5px 5px 5px #222);
        }

        @media #{$md} {
            margin-top: 40px;
        }

        @media #{$xs} {
            margin-top: 40px;
        }

    }

    .hero-content {
        border-radius: 0;
        position: relative;
        z-index: 1;
        text-align: left;

        h1 {
            font-size: 38px;
            font-weight: 800;
            line-height: 50px;
            color: $white;
            text-shadow: 0px 3px 8px #00000017;
            text-transform: capitalize;

            span {
                display: block;
            }
        }

        p {
            margin-top: 30px;
            font-size: 17px;
            color: $white;
        }

        .button {
            margin-top: 40px;

            .btn {
                background-color: $white;
                color: $theme-color;
                margin-right: 12px;

                i {
                    font-size: 17px;
                }

                &:hover {
                    background-color: $black;
                    color: $white;
                }

                &.btn-alt {
                    background-color: #ffffff6b;
                    color: #fff;

                    &:hover {
                        background-color: $white;
                        color: $theme-color;
                    }
                }
            }

            .video-button {
                @media #{$xs} {
                    margin-top: 20px;
                }

                .text {
                    display: inline-block;
                    margin-left: 15px;
                    color: $white;
                    font-weight: 500;
                }

                &:hover {
                    .video {
                        color: $white;
                        background-color: $black;
                    }
                }

                .video {
                    height: 50px;
                    width: 50px;
                    line-height: 50px;
                    text-align: center;
                    color: $theme-color;
                    background-color: $white;
                    border-radius: 50%;
                    margin-left: 10px;
                    font-size: 16px;
                    padding-left: 3px;
                    transition: all 0.4s ease-in-out;
                    position: relative;

                    &:before {
                        position: absolute;
                        content: '';
                        left: 0;
                        top: 0;
                        height: 100%;
                        width: 100%;
                        border: 1px solid $white;
                        border-radius: 50%;
                        -webkit-animation: pulse-border-2 2s linear infinite;
                        -moz-animation: pulse-border-2 2s linear infinite;
                        -o-animation: pulse-border-2 2s linear infinite;
                        animation: pulse-border-2 2s linear infinite;
                    }

                    @keyframes pulse-border {
                        0% {
                            -webkit-transform: scale(1);
                            -moz-transform: scale(1);
                            -ms-transform: scale(1);
                            -o-transform: scale(1);
                            transform: scale(1);
                            opacity: 1;
                        }

                        100% {
                            -webkit-transform: scale(1.3);
                            -moz-transform: scale(1.3);
                            -ms-transform: scale(1.3);
                            -o-transform: scale(1.3);
                            transform: scale(1.3);
                            opacity: 0;
                        }
                    }

                    @keyframes pulse-border-2 {
                        0% {
                            -webkit-transform: scale(1);
                            -moz-transform: scale(1);
                            -ms-transform: scale(1);
                            -o-transform: scale(1);
                            transform: scale(1);
                            opacity: 1;
                        }

                        100% {
                            -webkit-transform: scale(1.5);
                            -moz-transform: scale(1.5);
                            -ms-transform: scale(1.5);
                            -o-transform: scale(1.5);
                            transform: scale(1.5);
                            opacity: 0;
                        }
                    }
                }
            }
        }

        @media #{$lg} {
            h1 {
                font-size: 40px;
            }
        }

        @media #{$md} {

            text-align: center;

            h1 {
                font-size: 30px;
                font-weight: 700;
                line-height: 38px;
            }

            p {
                font-size: 17px;
            }
        }

        @media #{$xs} {
            padding: 0 10px;
            text-align: center;

            h1 {
                font-size: 24px;
                line-height: 32px;
            }

            p {
                margin-top: 15px;
                font-size: 14px;
                line-height: 22px;
            }

            .button {
                .btn {
                    width: 60%;
                    margin: 0;
                    margin-bottom: 7px;

                    &:last-child {
                        margin: 0;
                    }
                }
            }
        }
    }
}