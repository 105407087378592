/*======================================
    Testimonial CSS
========================================*/
.testimonials {
    background-color: $gray;
    position: relative;
    overflow: hidden;

    .tns-nav {
        text-align: center;
        position: absolute;
        bottom: 80px;
        transform: translateX(-50%);
        width: 100%;
        left: 50%;
        z-index: 9;

        @media #{$md} {
            bottom: 50px;
        }

        @media #{$xs} {
            bottom: 40px;
        }

        button {
            height: 6px;
            width: 14px;
            background-color: $black;
            border-radius: 5px;
            display: inline-block;
            border: none;
            margin: 0px 5px;
            transition: all 0.4s ease;

            &:hover {
                background: $theme-color;
            }

            &.tns-nav-active {
                background: $theme-color;
                width: 25px;
            }
        }
    }

    .testi-patern1 {
        width: 300px;
        position: absolute;
        left: -200px;
        bottom: -90px;

        @media #{$xs} {
            display: none;
        }
    }

    .testi-patern2 {
        width: 300px;
        position: absolute;
        right: -200px;
        top: -90px;

        @media #{$xs} {
            display: none;
        }
    }

    .single-testimonial {
        text-align: center;
        padding: 0px 80px 40px 80px;

        @media #{$xs} {
            padding: 0px 10px 20px 20px;
        }

        .text {
            .brand-icon {
                margin-bottom: 30px;

                img {
                    width: 140px;
                }
            }

            p {
                color: $black;
                font-size: 17px;
                font-weight: 600;
                line-height: 28px;

                @media #{$xs} {
                    font-size: 15px;
                    font-weight: 500;
                    line-height: 24px;
                }
            }
        }

        .author {
            margin-top: 30px;
            display: inline-block;
            position: relative;

            img {
                height: 50px;
                width: 50px;
                border-radius: 50%;
                display: inline-block;
            }

            .name {
                font-size: 15px;
                font-weight: 500;
                color: $black;
                display: block;
                margin-top: 10px;

                span {
                    color: #777;
                    display: block;
                    font-size: 13px;
                    margin-top: 4px;
                }
            }
        }
    }

}