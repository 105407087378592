/*======================================
	Mail Success CSS
========================================*/

.maill-success {
    height: 100vh;
    text-align: center;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.d-table {
    width: 100%;
    height: 100%;
}

.d-table {
    display: table !important;
}

.d-table-cell {
    vertical-align: middle;
}

.d-table-cell {
    display: table-cell !important;
}

.maill-success .success-content {
    i {
        font-size: 40px;
        margin-bottom: 20px;
        color: $theme-color;
    }

    h1 {
        font-size: 40px;
        color: $theme-color;
        margin-bottom: 10px;
        font-weight: 700;

        @media #{$md} {
            font-size: 30px;
        }

        @media #{$xs} {
            font-size: 22px;
        }
    }

    h2 {
        font-size: 18px;
        margin-bottom: 15px;
        color: $black;

        @media #{$md} {
            font-size: 15px;
        }

        @media #{$xs} {
            font-size: 15px;
        }
    }

    p {
        font-weight: 400;
        margin-bottom: 20px;
    }
}